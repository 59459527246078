import React ,  { Component } from "react";

class About extends Component{
    render(){
        return(
            <div className={`app-about ${this.props.horizontalabout}`}  id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>RUN ADS. <span className="theme-color">GET BOOKINGS.</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>Quickly select a service ad template, run your ad, generate the lead, and make the booking. You no longer need to pay an agency to do it for you.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 offset-lg-1 mt--40">
                            <div className="about-thumbnail mr--35">
                                <img className="image-1" src={require('../assets/images/app/Image_2.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/Image_2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/Image_2.png')} alt="App Landing"/>
                            </div>
                        </div>
                        <div className="col-lg-6 mt--40">
                            <div className="about-content">
                                <h2 className="title">IT’S THAT <span className="theme-color">EASY!</span></h2>
                                <p>Running ads, generating new customer leads and converting those leads into bookings is easy, fun and very affordable with the MAZUR Program. Once signed in to your account, you select the ad template that you would like to run. You then fill in several key pieces of information for your ad such as your city, your targeted audience, your daily budget and the details of the deal that you would like to run.</p>
                                <p>With just a few clicks and within two minutes you are running a new ad to generate new leads. No more endless Zoom calls with that expensive marketing agency. YOU are in CONTROL of your own ADS.</p>
                                <div className="about-buttons">
                                    {/*<a href="https://app.mazurprogram.com"><button type="button" className="button-default button-olive button-border">Download</button></a>*/}
                                    <a href="#download"><button type="button" className="button-default button-olive button-border">Download</button></a>
                                    <a className="button-default button-border" href="#support" role="button">BOOK DEMO</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default About;











