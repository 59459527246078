import React ,  { Component } from "react";



class Feature extends Component{
    render(){
       let data = [
           
        {
            image_path:'AUTOMATED_FUNNEL.png',
            icon: 'zmdi zmdi-collection-text',
            title: 'AUTOMATED FUNNEL',
            desc: 'The real star of the program is the automated sales funnel. Day or night the funnel sends out SMS messages, emails and even connects you directly to the new lead.'
        },

        {
            image_path:'PROVEN_ADS.png',
            icon: 'zmdi zmdi-sun',
            title: 'PROVEN ADS',
            desc: 'The ads are proven over years of testing and millions of ad impressions. The only thing left to do is add your deal, set your own budget and let the program do the rest.'
        },

        {
            image_path:'EMAIL_SMS_PHONE.png',
            icon: 'zmdi zmdi-brush',
            title: 'EMAIL, SMS & PHONE',
            desc: 'The program automatically connects to each new lead via email and sms messaging and during work hours the system will phone you and connect you to the lead.'
        },

        {
            image_path:'CONVERSATIONS.png',
            icon: 'zmdi zmdi-desktop-mac',
            title: 'CONVERSATIONS',
            desc: 'When a lead contacts you via phone, email, SMS or even messenger the entire communication flow is placed within the app into one conversation.'
        },

        {
            image_path:'INAPP_FB_ADS.png',
            icon: 'zmdi zmdi-language-html5',
            title: 'IN-APP FACEBOOK ADS',
            desc: 'You don’t need to know how to run Facebook Ads. You don’t even need to know how to use Facebook. Simply open the app and within minutes you are running ads.'
        }
       ] 

       let DataList = data.map((val , i) => {
           return(
               /* Start Single Feature */
               <div className="feature" key={i}>
                   <div className="feature-icon">
                       {/*<i className={`${val.icon}`}/>*/}
                       <img className="iconset-common-3" src={require('../assets/images/app/'+val.image_path)} alt="BOOKINGS"/>
                   </div>
                   <div className="content">
                       <h4 className="title">{val.title}</h4>
                       <p className="desc">{val.desc}</p>
                   </div>
               </div>
               /* End Single Feature */
           )
       })

       return(
           <div className= {`feature-area feature-bg-image pb--50 ${this.props.horizontalfeature}`} id="features">
               <div className="container">
                   <div className="row">
                       <div className="col-lg-12">
                            <div className="section-title text-center mb--40">
                                <h2>PROGRAM <span className="theme-color">FEATURES</span></h2>
                                <img className="image-1" src={require('../assets/images/app/title-icon.png')} alt="App Landing"/>
                                <img className="image-2" src={require('../assets/images/app/title-icon-2.png')} alt="App Landing"/>
                                <img className="image-3" src={require('../assets/images/app/title-icon-3.png')} alt="App Landing"/>
                                <p>The MAZUR Program includes everything that you need to generate your own leads. Let the program convert fresh hot leads into new bookings.</p>
                            </div>
                       </div>
                   </div>
                   <div className="row mt--30">
                       <div className="col-lg-7 offset-lg-5">
                            <div className="feature-list">
                                {DataList}
                            </div>
                       </div>
                   </div>
               </div>
           </div>
        )
    }
}

export default Feature;
